import React from "react";
import Encabezado from "./Encabezado";
import PieDePagina from "./PieDePagina";
import Cotizador from "./Cotizador";

function VistaCotizar(){
  return(
    <div>
      <Encabezado />
      <Cotizador />
      <PieDePagina />
    </div>
  );
}

export default VistaCotizar;