import React, { useState } from 'react';
import PieDePagina from './PieDePagina';
import Encabezado from './Encabezado';
import RecuperarPassPopUp from './RecuperarPassPopUp';
import '../stylesheets/MiCuenta.css';
import { useNavigate} from 'react-router-dom';
import { useScreen } from './ScreenContext';
import PopUpLogin from './PopUpLogin';


function MiCuenta(){

  const { setScreen } = useScreen(); 
  const navigate = useNavigate();

  const [showPopUp, setShowPopUp] = useState(false); //controla visibilidad del PopUp
  const [showPopUpLogin, setShowPopUpLogin] = useState(false); 

  const clickPopUp = () => {
    setShowPopUp(true); // Mostrar el PopUp al hacer clic en el botón
  };
  
  const handleClosePopUp = () => {
    setShowPopUp(false); // Ocultar el PopUp al hacer clic en x
  };

  const clickPopUpLogin = () => {
    setShowPopUpLogin(true); 
  };
  
  const handleClosePopUpLogin = () => {
    setShowPopUpLogin(false); 
  };

  const Registrar = () => {
    setScreen('Registro');
    navigate('/registrarse');
    window.scrollTo(0, 0);
  };

  return(
    <div className='contenedor-principal-mi-cuenta'>
      <div className='contenedor-encabezado-mi-cuenta'>
        <Encabezado></Encabezado>
        </div>
        <div className='contenedor-texto-mi-cuenta'>
          <div className='contenedor-acceso-clientes'>
            <div className='texto-acceso-clientes'>
              <a onClick={clickPopUpLogin} style={{ cursor: "pointer" }}> ACCESO CLIENTES </a>
            </div>
            {showPopUpLogin && <PopUpLogin handleClose={handleClosePopUpLogin}/>}

              <p>¿Todavía no tenés cuenta? </p>
              <div className='registrate-texto'> 
                <a onClick={Registrar} style={{ cursor: "pointer" }}> <b>REGISTRATE</b> </a>
              </div>
              <p> <a onClick={clickPopUp} style={{ cursor: "pointer" }}> ¿Olvidaste tu contraseña? </a></p>
              {showPopUp && <RecuperarPassPopUp handleClose={handleClosePopUp}/>} 

          </div>
        <div className='contenedor-imagen-cuenta'></div>
        </div>
      <PieDePagina></PieDePagina>
    </div>
  );
}

export default MiCuenta;