import React, { useState, useEffect  } from 'react';
import '../stylesheets/FormularioMundoRenta.css';
import { useNavigate} from 'react-router-dom';
import { useScreen } from './ScreenContext';
import PlanesMundoRenta from './PlanesMundoRenta';
import useGetEnvioMail from '../utils/ServiceEnvioMail';

function Formulario() {

  const {setScreen } = useScreen(); 
  const navigate = useNavigate();

  const RequisitosSeguro = () => {
    setScreen('RequisitosSeguro');
    window.open('/#/requisitos-seguro', '_blank');
    window.scrollTo(0, 0); 
  };

  const [formData, setFormData] = useState({
    TipoRenta: '',
    nombre: '',
    dni: '',
    domicilioParticular: '',
    telefono: '',
    fechaNacimiento: '',
    mail: '',
    domicilioPropiedad: '',
    formaPago: '',
    cbu: '',
    tipoTarjeta: 'Visa',
    numeroTarjeta: ''
  });

  const [formularioEnviado, setFormularioEnviado] = useState(false);
  const [mensajeEnvio, setMensajeEnvio] = useState('');

  const handleTipoRentaChange = (tipoRenta) => {
    setFormData({
      ...formData,
      TipoRenta: tipoRenta // Valor de TipoRenta con el botón seleccionado
    });

    if (formularioEnviado) {
      setFormularioEnviado(false);
    }
  };

  const handleFormaPagoChange = (formaPago) => {
    setFormData({
      ...formData,
      formaPago: formaPago // Valor de la forma de pago seleccionada
    });

    if (formularioEnviado) {
      setFormularioEnviado(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

   // Si el formulario ya ha sido enviado y se realiza un cambio en algún campo, establecer formularioEnviado a false
    if (formularioEnviado) {
      setFormularioEnviado(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Verificar que campos obligatorios 
    const { TipoRenta, nombre, dni, domicilioParticular, telefono, fechaNacimiento, mail, domicilioPropiedad, formaPago, cbu, tipoTarjeta, numeroTarjeta } = formData;
    if (!TipoRenta || !nombre || !dni || !domicilioParticular || !telefono || !fechaNacimiento || !mail || !domicilioPropiedad || !formaPago ||
        (formaPago === 'Débito automático' && !cbu) ||
        (formaPago === 'Tarjeta crédito' && (!numeroTarjeta))) {
      alert("Por favor, completa todos los campos obligatorios.");
      return;
    }

    setFormularioEnviado(true); // Actualiza el estado a enviado
    // Convertir formData a JSON
    const jsonData = JSON.stringify(formData);
    console.log(jsonData);
    // Acá enviar jsonData al servidor
  };

  const { MensajeMR, errorMR, loading } = useGetEnvioMail(formularioEnviado ? formData : null); //HOOK

  useEffect(() => {
    if (formularioEnviado && !loading) {
      if (errorMR === 0) {
        setMensajeEnvio('✅ Mensaje enviado correctamente');
      } else {
        setMensajeEnvio('❌ No pudimos enviar el mail, contáctese con nosotros por cualquiera de nuestras vías de contacto');
      }
    }
  }, [MensajeMR, errorMR, loading, formularioEnviado]);

    // useEffect para ajustar tipoTarjeta y cbu según formaPago
    useEffect(() => {
      if (formData.formaPago === 'Tarjeta crédito') {
        setFormData((prevFormData) => ({
          ...prevFormData,
          tipoTarjeta: 'Visa', // Valor predeterminado para tarjeta de crédito
          cbu: '' // Restablecer CBU a vacío si es tarjeta de crédito
        }));
      } else if (formData.formaPago === 'Débito automático') {
        setFormData((prevFormData) => ({
          ...prevFormData,
          tipoTarjeta: '', // Restablecer tipoTarjeta a vacío si es débito automático
          numeroTarjeta: '' // Restablecer número de tarjeta a vacío si es débito automático
        }));
      }
    }, [formData.formaPago]);


  return (
    <div className='box-formulario-MR'>
     <form onSubmit={handleSubmit} className='formulario-textos'>
        <div className='seccion-botones-formulario'>
          <div className='titulo-formulario-elegir'>
              ELEGÍ TU SEGURO
          </div>
          <button
            type="button"
            onClick={() => handleTipoRentaChange('DEPARTAMENTO')}
            className={formData.TipoRenta === 'DEPARTAMENTO' ? 'btn-tipoRenta-selected' : 'btn-tipoRenta'}
          >
            DEPARTAMENTO
          </button>
          <button
            type="button"
            onClick={() => handleTipoRentaChange('CASA 40 MTS')}
            className={formData.TipoRenta === 'CASA 40 MTS' ? 'btn-tipoRenta-selected' : 'btn-tipoRenta'}
          >
            CASA 40 MTS
          </button>
          <button
            type="button"
            onClick={() => handleTipoRentaChange('CASA 60 MTS')}
            className={formData.TipoRenta === 'CASA 60 MTS' ? 'btn-tipoRenta-selected' : 'btn-tipoRenta'}
          >
            CASA 60 MTS
          </button>
          <button
            type="button"
            onClick={() => handleTipoRentaChange('CASA/QUINTA 80 MTS')}
            className={formData.TipoRenta === 'CASA/QUINTA 80 MTS' ? 'btn-tipoRenta-selected' : 'btn-tipoRenta'}
          >
            CASA/QUINTA 80 MTS
          </button>
          </div>
          <div className='subtitulo-formulario-elegir'>
            *Algunos planes pueden ser más adecuados a tu inmueble de acuerdo al tamaño, por lo que si tu departamento supera los 60 MTS cuadrados, 
            será más conveniente una póliza de casa de 60 MTS
          </div>

        {/* desplegar formulario una vez elegido el tipo renta */}
          {formData.TipoRenta && (
            <div className='contenedor-formulario-MR'>
              <div className='info-plan-MR'>
                <PlanesMundoRenta tipoAlquiler={formData.TipoRenta}/>
              </div>
          <div className='titulo-formulario-elegir'>
            COMPLETÁ EL FORMULARIO
          </div>
          <label>
            <input
              placeholder='Nombre'
              type="text"
              name="nombre"
              value={formData.nombre}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            <input
              placeholder='DNI / CUIT'
              type="number"
              name="dni"
              value={formData.dni}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            <input
              placeholder='Domicilio Particular'
              type="text"
              name="domicilioParticular"
              value={formData.domicilioParticular}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            <input
              placeholder='Teléfono'
              type="tel"
              name="telefono"
              value={formData.telefono}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            <input
              placeholder='Fecha de Nacimiento'
              type="date"
              name="fechaNacimiento"
              value={formData.fechaNacimiento}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            <input
              placeholder='Mail'
              type="email"
              name="mail"
              value={formData.mail}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            <input
              placeholder='Domicilio de la Propiedad a Asegurar'
              type="text"
              name="domicilioPropiedad"
              value={formData.domicilioPropiedad}
              onChange={handleChange}
              required
            />
          </label>
      
          <div className='contenedor-formapago-enviar'>
            {/* Selector de forma de pago */}
                <div className='titulo-formulario-elegir'>
                  FORMA DE PAGO
                </div>
                  <div className='elegir-formapago-formulario'>
        
                    <div className='opcion-formapago-formulario'>
                      <button
                        type="button"
                        onClick={() => handleFormaPagoChange('Débito automático')}
                        className={formData.formaPago === 'Débito automático' ? 'btn-formaPago-selected' : 'btn-formaPago'}
                      >
                        Débito automático
                      </button>
                      <button
                        type="button"
                        onClick={() => handleFormaPagoChange('Tarjeta crédito')}
                        className={formData.formaPago === 'Tarjeta crédito' ? 'btn-formaPago-selected' : 'btn-formaPago'}
                      >
                        Tarjeta crédito
                      </button>
                    </div>
                  
                  {/* Input para CBU */}
                  {formData.formaPago === 'Débito automático' && (
                    <label>
                      <input
                        placeholder='CBU'
                        type="text"
                        name="cbu"
                        value={formData.cbu}
                        onChange={handleChange}
                        required
                      />
                    </label>
                  )}
  
                  {/* Dropdown e input para Tarjeta crédito */}
                  {formData.formaPago === 'Tarjeta crédito' && (
                    <>
                    <div className='contenedor-tarjeta-numero'>
                      <select
                        name="tipoTarjeta"
                        value={formData.tipoTarjeta}
                        onChange={handleChange}
                        className='dropdown-tarjeta'
                      >
                        <option value="Visa">Visa</option>
                        <option value="Master">Master</option>
                        <option value="Naranja">Naraja</option>
                        <option value="Amex">Amex</option>
                      </select>
                      <label>
                        <input
                          placeholder='Número de tarjeta'
                          type="number"
                          name="numeroTarjeta"
                          value={formData.numeroTarjeta}
                          onChange={handleChange}
                          required
                        />
                      </label>
                      </div>
                    </>
                  )}
                </div>
                <button type="submit"> ENVIAR </button>
            </div>

            <div className='titulo-formulario-elegir'>
              <a  onClick={RequisitosSeguro}>*REQUISITOS PARA CONTRATAR EL SEGURO*</a>
            </div>
            </div>  
        )}
        
        {formularioEnviado && (
          <div className="mensaje-enviado-formulario">
          <p>{loading ? 'Enviando...' : mensajeEnvio}</p>
        </div>
				  
        )}

          </form>
    </div>
  );

}

export default Formulario;
