import React from 'react';
import PieDePagina from './PieDePagina';
import Encabezado from './Encabezado';
import '../stylesheets/Negocio.css';
import imgNegocio from '../media/landing1-(1).png'
import { useNavigate} from 'react-router-dom';
import { useScreen } from './ScreenContext';


function Negocio(){

    const {setScreen } = useScreen();
    const navigate = useNavigate(); 

    const RequisitosNegocio = () => {
        setScreen('RequisitosNegocio');
        window.open('/#/requisitos-negocio', '_blank');
        window.scrollTo(0, 0); 
      };

    const MiCuenta = () => {
        setScreen('MiCuenta');
        navigate('/micuenta');
        window.scrollTo(0, 0); 
    };

  return(
    <div className='contenedor-principal-negocio'>
      <div className='contenedor-encabezado'>
        <Encabezado></Encabezado>
        </div>
      <div className='contenedor-negocio'>
        <div className='contenedor-blanco-negocio'>
            <div className='texto-principal-negocio'>
                <p>Sabemos que tu negocio es tu motor de vida,</p>
                <p><b>te contamos cómo protegerlo</b></p>
                    <div className='subrayado-negocio'></div>
                <p>Tu <b>negocio</b> requiere una protección personalizada</p>
                <p>para cada actividad. Por eso, en vez de cotizar online,</p>
                <p>uno de nuestros asesores te espera para poder diseñar</p>
                <p>una póliza a la medida de tus necesidades.</p>
            </div>
            <a href="https://wa.me/5491127864306?text=¡Hola!" target="_blank">
                <button className='btn-negocio'>
                    QUIERO QUE ME CONTACTEN
                </button>
            </a>
            <div className='subtitulo-negocio'>
                INCENDIO EDIFICIO
            </div>
            <div className='texto-negocio'>
                <p>por todo el local.</p>
            </div>
            <div className='subtitulo-negocio'>
                INCENDIO CONTENIDO
            </div>
            <div className='texto-negocio'>
                <p>por toda la mercadería, mobiliario e instalaciones.</p>
            </div>
            <div className='subtitulo-negocio'>
                ROBO
            </div>
            <div className='texto-negocio'>
                <p>para cubrir tus elementos.</p>
                <p>Ojo que el hurto no está cubierto.</p>
                <p>Hurto (video explicativo)</p>
            </div>
            <div className='subtitulo-negocio'>
                TODO RIESGO
            </div>
            <div className='texto-negocio'>
                <p>para equipos electrónicos.</p>
            </div>
            <div className='subtitulo-negocio'>
                RESPONSABILIDAD CIVIL
            </div>
            <div className='texto-negocio'>
                <p>por si le pasa algo a alguien dentro o a tus vecinos.</p>
            </div>
            <div className='subtitulo-negocio'>
                CRISTALES
            </div>
            <div className='texto-negocio'>
                <p>Ventanas, vidrieras, espejos, siempre verticales.</p>
            </div>
                <button className='btn-negocio' 
                    style={{ marginBottom: '-2%' }}
                    onClick={MiCuenta}>
                        QUIERO MI SEGURO
                </button>
        </div>
        <div className='contenedor-fondo-negocio'>
            <div className='subtitulo-negocio-2'>
                ¿QUÉ DOCUMENTACIÓN NECESITO PRESENTAR PARA CONTRATAR ESTE SEGURO?                    
            </div>
            <div className='texto-negocio'>
                <p>Fotografías del inmueble y su contenido a asegurar.</p>
                <p>Cumplir con los requisitos de suscripción.</p>
                <p>Materiales de la construcción:</p>
                <p>mampostería, construcción en seco ignífuga o mixta (madera o chapa hasta 50%).
                Sujeto a aceptación de la empresa.</p>
                <p>Contar con sistemas de seguridad, cerraduras doble paleta o bidimensionales,
                rejas en todas las ventanas, cerco perimetral de 1.80 de alto mínimo.</p>
            </div>
            <div className='requisitos-titulo-negocio'>
                <a  onClick={RequisitosNegocio}>Requisitos de Suscripción completos.</a>
            </div>
            <div className='requisitos-negocio'>
                <p>*En caso de no cumplir con alguno de estos requisitos</p>
                <p>comuníquese para una asesoría personalizada*</p>
            </div>
            <div className='imagen-casa-negocio'>
                <img
                    className='img-negocio'
                    src={imgNegocio}
                />
            </div>
        </div>
      </div>
      <PieDePagina></PieDePagina>
    </div>
  );
}

export default Negocio;