import React from 'react';
import config from '../config';
import PieDePagina from './PieDePagina';
import Encabezado from './Encabezado';
import '../stylesheets/Registro.css';


function Registro(){

  return(
    <div className='contenedor-principal-registro'>
      <div className='contenedor-encabezado-registro'>
        <Encabezado></Encabezado>
        </div>
        <div className='contenedor-texto-registro'>
          
          {/* Contenedor imagen */}
          <div className='contenedor-imagen-registro'></div>

          {/* Contenedor texto */}
          <div className='contenedor-texto-registrate'>
          <p>REGISTRATE</p>
          <div className='subrayado-registrate'></div>
            <div className='contenedor-registrate'>
                <div className='contenedor-backoffice-registro'>
                  <div className="iframe-container-registro">
                    <iframe
                        className='iframe-registro'
                        title="Backoffice"
                        src={config.registroUrl}
                        width= "490vw"
                        height= "435.8vh"
                        frameBorder="0" 
                        scrolling="auto"
                    ></iframe>
                  </div>
                </div>
                {/* <button className='btn-cancelar-registro' onClick={MiCuenta}>
                CANCELAR
            </button> */}
            </div>
          </div>
          
        </div>
      <PieDePagina></PieDePagina>
    </div>
  );
}

export default Registro;