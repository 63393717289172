import React from 'react';
import PieDePagina from './PieDePagina';
import Encabezado from './Encabezado';
import '../stylesheets/Asociaciones.css';


function Asociaciones(){
    return(
        <div className='contenedor-principal-asociaciones'>
          <div className='contenedor-encabezado'>
            <Encabezado></Encabezado>
            </div>
          <div className='contenedor-asociaciones'>
            <div className='contenedor-blanco-asociaciones'>
                <div className='texto-principal-asociaciones'>
                    <p><b>Si sos parte de una Asociación de guías profesionales</b></p>
                    <p>podés beneficiarte ampliamente con nuestras</p>
                    <p><b>pólizas colectivas.</b></p>
                        <div className='subrayado-asociaciones'></div>
                    <p>Para muchas actividades, deberás contar</p>
                    <p>con ciertos seguros como requisito.</p>
                    <p>Las asociaciones como proyectos mutuales permiten</p>
                    <p>que una póliza cueste una fracción de lo que sería su costo</p>
                    <p>si fuese individual.</p>
                </div>
            </div>
            <div className='contenedor-fondo-asociaciones'>
                <div className='subtitulo-asociaciones'>
                    NUESTROS SEGUROS COLECTIVOS CUBREN:                    
                </div>
                <div className='texto-asociaciones'>
                    <p><span className="check-mark">✔</span><b>Accidentes personales</b></p>
                    <p>para guía y turistas</p>
                    <p><span className="check-mark">✔</span><b>Responsabilidad Civil comprensiva</b></p>
                    <p><span className="check-mark">✔</span><b>Responsabilidad Civil profesional</b></p>
                    <p><span className="check-mark">✔</span><b>Seguro de viaje</b></p>
                    <p><span className="check-mark">✔</span><b>Seguro de vida</b></p>
                </div>
                <div className='texto-asociaciones'>
                    <p>Algunas profesiones están en riesgo de demandas,</p>
                    <p>la responsabilidad civil de una póliza colectiva protege a los</p>
                    <p>profesionales frente a diversos riesgos que puede implicar</p>
                    <p>su práctica profesional.</p>
                </div>
                <a href="https://wa.me/5491127864306?text=¡Hola!" target="_blank">
                    <button className='btn-asociaciones'>
                        QUIERO ASESORARME
                    </button>
                </a>
            </div>
          </div>
          <PieDePagina></PieDePagina>
        </div>
      );
    }

export default Asociaciones;