import React, { useEffect, useState } from 'react';
import PieDePagina from './PieDePagina';
import Encabezado from './Encabezado';
import '../stylesheets/MundoRenta.css';
import MundoRentaLogo from '../media/mundorenta.png'
import FormularioMundoRenta from './FormularioMundoRenta'
import { useNavigate} from 'react-router-dom';
import { useScreen } from './ScreenContext';


function MundoRenta(){

    const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        setIsDesktop(window.innerWidth > 768); // Definir el ancho mínimo para considerar como escritorio
      };
  
      window.addEventListener('resize', handleResize);
      handleResize(); // Llamar a la función inicialmente para establecer el estado correcto
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const {setScreen } = useScreen(); 
    const navigate = useNavigate();
  
    const RequisitosSeguro = () => {
      setScreen('RequisitosSeguro');
      window.open('/#/requisitos-seguro', '_blank');
      window.scrollTo(0, 0); 
    };

  return(
    <div className='contenedor-principal-MR'>
      <div className='contenedor-encabezado'>
        <Encabezado></Encabezado>
        </div>
        <div>
            <div className='contenedor-contenido-MR'>
                <div className='contenedor-MR'>
                    <div className="contenedor-imagen-MR"> 
                    {/* {isDesktop &&  */}
                        <iframe
                            width="85%"
                            height="100%"
                            src="https://www.youtube.com/embed/G5XnV7e3l8c?si=uD0WQKzc-aMdQkAo"
                            title="Video YouTube"
                            frameBorder="0"
                            allowFullScreen
                        ></iframe> 
                    {/* }  */}
                    </div>

                    {/* Contenedor de texto */}
                    <div className="contenedor-texto-MR">
                        <div className='titulo-MR'>
                            <p>BIENVENIDO AL PORTAL MULTISEGURIA.COM PARA</p>
                        </div>
                        <div>
                        <img 
                            src={MundoRentaLogo} alt='Imagen Mundo Renta'
                            className='imagen-logo-MR'
                            height='100px'
                            />
                            {/* hay que poner logo real */}
                        </div>      
                        <div >
                            <div className='texto-after-titulo-MR'>
                                <p>A continuación, encontrarás información sobre el seguro que estás por adquirir.</p>
                                <p>Al finalizar, encontrarás las opciones que se ajustan a tu propiedad.</p>
                            </div>
                            <div className='subtitulo-MR'>
                                ¿QUÉ CUBRE Y QUÉ NO CUBRE?
                            </div>
                            <div className='texto-n-MR'>
                                Un seguro especializado para tu inmueble ya sea casa, cabaña o departamento, cubre:
                            </div>
                            <div className='texto-MR'>
                                <p>Incendio del edificio y su contenido.</p>
                                <p>Daños por granizo y HCVT* (Huracán, ciclón, vendaval o tornado).</p>
                                <p>Robo del contenido.</p>
                                <p>TODO RIESGO de equipos electrónicos fijos.</p>
                                <p>Rotura de Cristales.</p>
                                <p>Responsabilidad Civil: daños físicos y materiales que pueda sufrir un inquilino dentro del inmueble,
                                linderos, suministro de alimentos, tenencia de animales domésticos y piletas (con la condición de que
                                tenga un cerco perimetral.)</p>
                            </div>
                            <div className='texto-n-MR'>
                                No cubre:            
                            </div>
                            <div className='texto-MR'>
                                <p>Los bienes de pasajeros por robo o hurto.</p>
                                <p>Terremotos.</p>
                                <p>Depreciación, desgaste o deterioro de electrónicos por uso.</p>
                                <p>Pieza vítreas que no estén en posición vertical (techos o aleros).</p>
                            </div>
                            <div className='subtitulo-MR'>
                                ¿QUÉ DOCUMENTACIÓN NECESITO PRESENTAR PARA CONTRATAR ESTE SEGURO?            
                            </div>
                            <div className='texto-n-MR'>
                                Cumplir con las requisitos de suscripción        
                            </div>
                            <div className='texto-MR'>
                                <a  onClick={RequisitosSeguro}>
                                    <p>(Normas de Suscripción completas)</p>
                                </a>
                                <p>*En caso de no cumplir con alguno de estos requisitos comuníquese para una asesoría personalizada*  </p>
                            </div>
                            <div className='subtitulo-MR'>
                                MEDIDA DE LA PRESTACIÓN
                                <a href='https://youtu.be/3rn178EK_CM?si=FTEI2UisX3ZKfzRK' className='enlace-video-MR'> (video explicativo)</a>
                            </div>
                            <div className='texto-MR'>
                                <p>A primer riesgo absoluto en todas las coberturas</p>
                            </div>
                            <div className='subtitulo-MR'>
                                FRANQUICIA
                                <a href='https://youtu.be/kUDIZDmChLg?si=Kiwy43u19riSehlW' className='enlace-video-MR'> (video explicativo)</a>
                            </div>
                            <div className='texto-MR'>
                                <p>No, solo tiene franquicia la cobertura de responsabilidad civil.</p>
                                <p>(el 10% del siniestro, tiene un máximo de 5% de suma asegurada y un mínimo 1%de suma asegurada)</p>
                            </div>
                        </div>
                    </div>
                </div> 
                <div className='contenedor-formulario-MR'>
                    <FormularioMundoRenta></FormularioMundoRenta>
                </div>               
            </div>
        </div> 
        <PieDePagina></PieDePagina>
    </div>
  );
}

export default MundoRenta;