import React from 'react';
import config from '../config';
import '../stylesheets/PopUpLogin.css';
import '../stylesheets/MiCuenta.css'
import cerrar from '../media/x.png';

function PopUpLogin({ handleClose}) {
  return (
    <div className="popup-login-contenedor">
      <div className="popup-login">
        <div class="iframe-container">
                <iframe
                  className='iframe-mi-cuenta'
                  title="Backoffice"
                  src={config.loginUrl}
                  width= "750vw"
                  height= "650vh"
                  frameBorder="0"
                  scrolling="auto"
                  // style={{ transform: 'scale(1)', transformOrigin: '0 0', marginBottom: '10px' }}
                ></iframe>
              </div>
        
        <div className="cerrarPopUp-btn" onClick={handleClose}>
            <img src={cerrar} alt="Ícono de Cierre" />
          </div>
      </div>
    </div>
  );
}

export default PopUpLogin;
