import React, { useState, useEffect } from 'react';
import '../stylesheets/TipoSeguro.css';

function SeguroMapfre( {iconoSeguro, nombreSeguro, url} ){

  const [hovered, setHovered] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768); // Definir el ancho mínimo para considerar como escritorio
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Llamar a la función inicialmente para establecer el estado correcto
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClick = () => {
    window.open(url, '_blank'); // Abre la URL en una nueva pestaña
  };

  const handleDoubleClick = () => {
    handleClick();
  };

  const handleMouseOver = () => {
    setHovered(true);
  };
  
  const handleMouseOut = () => {
    setHovered(false);
  }; 

  return(
    <>
      { isDesktop && (
        <button
          className="cotizador-inicio"
          onClick={handleClick}
          onDoubleClick={handleDoubleClick}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <div className='contenedor-icono-y-texto'>
            <img 
              src={iconoSeguro} 
              alt={nombreSeguro} 
              className="icono-tipo-seguro-inicio"
            />
            <div className={hovered ? 'hover-icono-seguro-hovered' : 'hover-icono-seguro'}></div>
            <div className='contenedor-nombre-tipo-seguro'>
              <div className={`${hovered ? 'nombre-tipo-seguro-hovered' : 'nombre-tipo-seguro'}`}>
                {nombreSeguro}
              </div>
            </div>
          </div>
        </button>
      )}

      { !isDesktop && (
        <button
          className='cotizador-inicio'
          onClick={handleClick}
          onDoubleClick={handleDoubleClick}
        >
          <div className='contenedor-icono-y-texto'>
            <img 
              src={iconoSeguro} 
              alt={nombreSeguro} 
              className='icono-tipo-seguro-inicio'
            />
            <div className='contenedor-nombre-tipo-seguro'>
              <div className='nombre-tipo-seguro'>{nombreSeguro}</div>
            </div>
          </div>
        </button>
      )}
    </>
  );
}

export default SeguroMapfre;
