import React, { useState, useEffect } from "react";
import '../stylesheets/NegocioSeccion.css';
import imgNegocio from '../media/banner1 (1).png'
import { useNavigate} from 'react-router-dom';
import { useScreen } from './ScreenContext';

function NegocioSeccion(){

  const [isDesktop, setIsDesktop] = useState(false);

  // Definir el ancho mínimo para considerar como escritorio
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768); 
    };

    // Llamar a la función inicialmente para establecer el estado correcto
    window.addEventListener('resize', handleResize);
    handleResize(); 
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const {setScreen } = useScreen(); 
  const navigate = useNavigate();
  
  const Negocio = () => {
    setScreen('Negocio');
    navigate('/negocio');
    window.scrollTo(0, 0); 
  };


  return(
    <div className="contenedor-principal-seccion-negocio">
      <div className="contenedor-fondo-seccion-negocio">
        {/* {isDesktop && // Mostrar solo si es escritorio */}
          <div className="imagen-casa-negocio-seccion">
            <img 
                className="imagen-negocio-seccion"
                src={imgNegocio}
            />
          </div>
        {/* } */}
        <div className="contenedor-texto-seccion-negocio">
            <div className="titulo-seccion-negocio">
                <p className="primer-p">TU SUEÑO EMPRESARIAL ESTÁ</p>
                <p><b>SEGURO CON NOSOTROS</b></p>
            </div>
            <button className='btn-negocio-seccion' onClick={Negocio}>
                CONTAME MÁS
            </button>
        </div>
      </div>
    </div>
    
  );
}

export default NegocioSeccion;