import React from 'react';
import '../stylesheets/BotonCotizar.css'; 
import { useNavigate } from 'react-router-dom' // npm install react-router-dom
import { useScreen } from './ScreenContext';

function BotonCotizar ({ onClick}) {

  const navigate=useNavigate()

  const { currentScreen, setScreen } = useScreen(); 


  const Cotizador = () => {
    setScreen('Cotizador');
    navigate('/cotizador');
  }
  
  return (
    <div className='centrar'>
      <button 
        className="btn-cotizar" 
        onClick={Cotizador}>
          COTIZAR
      </button>
    </div>
  );
};

export default BotonCotizar;
