import { useAuth } from '../componentes/AuthContext';
import { useState, useEffect } from 'react';

const useGetParams = (EmpCodPlanes, ProTipCodPlanes, 
  TabCodPlanes, TabCodCelPlanes, LongSDT) => {

  const {token, loadingToken, client} = useAuth();
  const [bodyCotizarRest, setBodyCotizarRest] = useState([]);

  // Obtener parámetros para el body del servicio getCotizaciones
  useEffect(() => {
    const data = {
      EmpCod: EmpCodPlanes,
      ProTipCod: ProTipCodPlanes,
	    TabCod: TabCodPlanes,
      TabCodCel: TabCodCelPlanes,
      LongSDT: LongSDT
      };
      client.post('/rest/CotizarRest', data)
      .then(response => {
        setBodyCotizarRest(response.data);
      })
      .catch(error => {
        console.error('Error al cargar datos para body de cotización:', error);
        console.log('JSON enviado: ', data);
      });
  }, []);

  return bodyCotizarRest;
}

export default useGetParams;