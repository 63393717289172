import { useAuth } from '../componentes/AuthContext';
import { useState, useEffect } from 'react';

const useGetSeguros = () => {
  const {token, loadingToken, client} = useAuth();
  const [seguros, setSeguros] = useState([]);

  useEffect(() => {
    const obtenerSeguros = async () => {
      try {
        const response = await client.post('/rest/getPrimerasTablas');
        console.log('PRODUCTOS: ', response.data);

        const segurosObtenidos = response.data.PrimerasTablas.map(item => ({
          id: item.PrimeraTabla.ProTipCod,
          nombreProducto: item.PrimeraTabla.ProTipDes.toUpperCase(),
          EmpCod: item.PrimeraTabla.EmpCod,
          ProTipCod: item.PrimeraTabla.ProTipCod,
          TabCod: item.PrimeraTabla.TabCod,
          TabCodCel: item.PrimeraTabla.TabCodCel,
          AseCod: item.PrimeraTabla.AseCod,
          icono: item.PrimeraTabla.PorTipImg
        }));

        setSeguros(segurosObtenidos);
      } catch (error) {
        console.error('Error al cargar primeras tablas:', error);
        throw error;
      }
    };

    if (!loadingToken) {
        obtenerSeguros();
        console.log(token);
      }

  }, [token, loadingToken]);

  return seguros;
};

export default useGetSeguros;
