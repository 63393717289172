import React, { useState, useEffect } from "react";
import '../stylesheets/AsociacionesSeccion.css';
import { useNavigate} from 'react-router-dom';
import { useScreen } from './ScreenContext';

function AsociacionesSeccion(){

  const {setScreen } = useScreen(); 
  const navigate = useNavigate();
  
  const Asociaciones = () => {
    setScreen('Asociaciones');
    navigate('/asociaciones');
    window.scrollTo(0, 0); 
  };

  return(
    <div className="contenedor-principal-seccion-asociaciones">
      <div className="contenedor-fondo-seccion-asociaciones">
        <div className="contenedor-texto-seccion-asociaciones">
            <div className="titulo-seccion-asociaciones">
                <p>PERTENECER A UNA ASOCIACIÓN</p>
                <p><b>DE GUÍAS PROFESIONALES</b></p>
                <p>TIENE SUS BENEFICIOS</p>
            </div>
            <button className='btn-asociaciones-seccion' onClick={Asociaciones}>
                Necesito un asesor
            </button>
        </div>
      </div>
    </div>
    
  );
}

export default AsociacionesSeccion;