import React, { createContext, useContext, useState } from 'react';

const ScreenContext = createContext();

export const ScreenProvider = ({ children }) => {
  const [currentScreen, setCurrentScreen] = useState('Inicio');

  const setScreen = (screen) => {
    setCurrentScreen(screen);
  };

  return (
    <ScreenContext.Provider value={{ currentScreen, setScreen }}>
      {children}
    </ScreenContext.Provider>
  );
};

export const useScreen = () => {
  const context = useContext(ScreenContext);
  if (!context) {
    throw new Error('useScreen must be used within a ScreenProvider');
  }
  return context;
};
