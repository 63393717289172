import React, { useState, useEffect } from 'react';
import '../stylesheets/TipoSeguro.css';
import { useScreen } from './ScreenContext';

function TipoSeguroBtn( {id, iconoSeguro, nombreSeguro, EmpCod, ProTipCod, 
TabCod, TabCodCel, AseCod, handleClick, setSeguroElegido, isClicked} ){

  const {currentScreen} = useScreen();
  const [hovered, setHovered] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768); // Definir el ancho mínimo para considerar como escritorio
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Llamar a la función inicialmente para establecer el estado correcto
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const handleClickWrapper = () => {
    if (!isClicked) {
      handleClick();
      setSeguroElegido(id, nombreSeguro, iconoSeguro, EmpCod, ProTipCod, TabCod, TabCodCel, AseCod);
      // if ((currentScreen === 'Asesoria') && (isDesktop=== false)) {
      if ((currentScreen === 'Asesoria')) {
        window.scrollTo({ top: 1500, behavior: 'smooth' });
         }
    } else {
      setSeguroElegido(null, null, null, null, null, null, null, null);
      
    }
  }

  const handleDoubleClickWrapper = () => {
    handleClickWrapper();
  };

  const handleMouseOver = () => {
      setHovered(true);
    };
  
  const handleMouseOut = () => {
    setHovered(false);
  }; 


  return(
    <>
      { isDesktop && (
        <button
          className={(currentScreen === 'Inicio' || currentScreen === 'Cotizador') ? 'cotizador-inicio' : 'cotizador-asesoria-plus'}
          onClick={handleClickWrapper}
          onDoubleClick={handleDoubleClickWrapper}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          >
          <div className='contenedor-icono-y-texto'>
              <img 
                src={`data:image/png;base64,${iconoSeguro}`} 
                alt={nombreSeguro} 
                className={(currentScreen === 'Inicio' || currentScreen === 'Cotizador') ? 'icono-tipo-seguro-inicio'
                            : 'icono-tipo-seguro-asesoria-plus'}
              />
              <div className={`contenedor-hover-icono ${isClicked ? 'selected' : 'not-selected'}`}>
              <div className={hovered ? ((currentScreen === 'Inicio' || currentScreen === 'Cotizador') ?
                 'hover-icono-seguro-hovered' : 'hover-icono-seguro-hovered-AP') : 
                 'hover-icono-seguro'}></div>
              </div>
              <div className={hovered  ? 'contendor-nombre-tipo-seguro-hovered'
                              : 'contenedor-nombre-tipo-seguro'}>
              <div className={`${hovered || isClicked ? 'nombre-tipo-seguro-hovered' : 'nombre-tipo-seguro'}`}>
                {nombreSeguro}
              </div>
              </div>
              
            </div>
        </button>
      )}

      { !isDesktop && (
              <button
                className={(currentScreen === 'Inicio' || currentScreen === 'Cotizador') ? 'cotizador-inicio' : 'cotizador-asesoria-plus'}
                onClick={handleClickWrapper}
                onDoubleClick={handleDoubleClickWrapper}
                >
                  <div className='contenedor-icono-y-texto'>
                    <img 
                      src={`data:image/png;base64,${iconoSeguro}`} 
                      alt={nombreSeguro} 
                      className={(currentScreen === 'Inicio' || currentScreen === 'Cotizador') ? 'icono-tipo-seguro-inicio'
                                  : 'icono-tipo-seguro-asesoria-plus'}
                    />
                    <div className={`contenedor-hover-icono ${isClicked ? 'selected' : 'not-selected'}`}>
                      <div className='hover-icono-seguro'></div>
                    </div>
                    
                    <div className='contenedor-nombre-tipo-seguro'>
                      <div className='nombre-tipo-seguro'>{nombreSeguro}</div>
                    </div>
                  </div>
              </button>
            )}
    </>
  );
}

export default TipoSeguroBtn;