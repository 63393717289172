import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Inicio from './componentes/Inicio';
import Cotizador from './componentes/Cotizador';
import { AuthProvider } from './componentes/AuthContext';  // Importa el AuthProvider
import AsesoriaPlus from './componentes/AsesoriaPlus';
import Nosotros from './componentes/Nosotros';
import MiCuenta from './componentes/MiCuenta';
import { ScreenProvider } from './componentes/ScreenContext';
import VistaCotizar from './componentes/VistaCotizar';
import Registro from './componentes/Registro';
import MundoRenta from './componentes/MundoRenta';
import Negocio from './componentes/Negocio';
import Asociaciones from './componentes/Asociaciones';
import RequisitosSeguro from './componentes/RequisitosSeguro';
import RequisitosNegocio from './componentes/RequisitosNegocio';



const root = ReactDOM.createRoot(document.getElementById('root'));

function App() {
  useEffect(() => {
    document.title = "Multiseguria"; // Título Pestaña
  }, []);

  
// Agrego icono a la pestaña del navegador
  const link = document.createElement('link');
  link.rel = 'icon';
  link.href = '/Multiseguria Isotipo-02.png'; // Ruta al icono en la carpeta public
  document.head.appendChild(link);

// Redirigir desde componente Inicio a componente Cotizador
return (
  <Router>
    <AuthProvider> {/* Envuelvo rutas con AuthProvider */}
    <ScreenProvider>
      <Routes>
        <Route path="/" element={<Inicio />} />
        <Route path="/cotizador" element={<VistaCotizar />} />
        <Route path='/asesoriaplus' element={<AsesoriaPlus />} />
        <Route path='/nosotros' element={<Nosotros />} />
        <Route path='/micuenta' element={<MiCuenta />} />
        <Route path='/registrarse' element={<Registro />} />
        <Route path='/mundorenta' element={<MundoRenta />} />
        <Route path='/negocio' element={<Negocio />} />
        <Route path='/asociaciones' element={<Asociaciones />} />
        <Route path='/requisitos-seguro' element={<RequisitosSeguro />} />
        <Route path='/requisitos-negocio' element={<RequisitosNegocio />} />
      </Routes>
    </ScreenProvider>
    </AuthProvider>
  </Router>
);
}

root.render(<App />);