import React, { useState, useEffect } from 'react';
import logo from '../media/Logo_multiseguria.png';
import menu from '../media/menu.png';
import cerrar from '../media/x.png';
import '../stylesheets/Encabezado.css';
import '../stylesheets/BotonCotizar.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useScreen } from './ScreenContext';

function Encabezado({ onClick }) {
  const [mostrarBarraLateral, setMostrarBarraLateral] = useState(false);
  const { currentScreen, setScreen } = useScreen(); 
  const navigate = useNavigate();
  const location = useLocation();

  const toggleBarraLateral = () => {
    setMostrarBarraLateral(!mostrarBarraLateral);
  };

  const cerrarBarraLateral = () => {
    setMostrarBarraLateral(false);
  };

  useEffect(() => {
    const cerrarMenuAlClicFuera = (event) => {
      // Verifica si el clic se produce fuera del botón de menú y del menú
      if (
        mostrarBarraLateral &&
        !event.target.closest('.toggle-btn') &&
        !event.target.closest('.nav-container')
      ) {
        cerrarBarraLateral();
      }
    };

    // Agrega el manejador de eventos al documento
    document.addEventListener('click', cerrarMenuAlClicFuera);

    // Limpia el manejador de eventos al desmontar el componente
    return () => {
      document.removeEventListener('click', cerrarMenuAlClicFuera);
    };
  }, [mostrarBarraLateral]);

  //const screen=useScreen();

  const asesoriaPlus = () => {
    setScreen('Asesoria');
    navigate('/asesoriaplus');
  };

  const Inicio = () => {
    setScreen('Inicio');
    navigate('/');
  };

  const Cotizador = () => {
    setScreen('Cotizador');
    navigate('/cotizador');
  };
  
  const Nosotros = () => {
    setScreen('Nosotros');
    navigate('/nosotros');
  };

  const MiCuenta = () => {
    setScreen('MiCuenta');
    navigate('/micuenta');
  };

  const MundoRenta = () => {
    setScreen('MundoRenta');
    navigate('/mundorenta');
  };
  
  const handleLogoClick = () => {
    if (location.pathname === '/') {
      window.location.reload();
    } else {
      Inicio();
    }
  };

  return (
    <>
      <header>
        <div className="logo" onClick={handleLogoClick}>
          <img src={logo} alt="Logo" />
        </div>
        <div className='barras'>
          <button className="toggle-btn" onClick={toggleBarraLateral}>
            <img src={menu} alt="Icono Menú" />
          </button>
        </div>
        <nav className={mostrarBarraLateral ? 'active' : ''} onClick={cerrarBarraLateral}>
          <div className="cerrar-btn" onClick={cerrarBarraLateral}>
            <img src={cerrar} alt="Ícono de Cierre" />
          </div>
          <ul>
          <li>
              <a className={location.pathname !== '/cotizador' && currentScreen === 'Inicio' ? 'opcion-header-subrayado' : 'opcion-header'} onClick={Inicio}>INICIO</a>
            </li>
            <li>
              <a className={location.pathname !== '/cotizador' && currentScreen === 'Asesoria' ? 'opcion-header-subrayado' : 'opcion-header'} onClick={asesoriaPlus}>ASESORÍA PLUS</a>
            </li>
            <li>
              <a className={location.pathname !== '/cotizador' && currentScreen === 'Nosotros' ? 'opcion-header-subrayado' : 'opcion-header'} onClick={Nosotros}>NOSOTROS</a>
            </li>
            <li>
              <a className={location.pathname !== '/cotizador' && currentScreen === 'MiCuenta' ? 'opcion-header-subrayado' : 'opcion-header'} onClick={MiCuenta}>MI CUENTA</a>
            </li>
            <li>
              <a className={location.pathname !== '/cotizador' && currentScreen === 'MundoRenta' ? 'opcion-header-subrayado' : 'opcion-header'} onClick={MundoRenta}>MUNDO RENTA</a>
            </li>
            <div className='centrar'>
              <button className="btn-cotizar-encabezado" onClick={Cotizador}>
                COTIZAR
              </button>
            </div>
          </ul>
        </nav>
      </header>
    </>
  );
}

export default Encabezado;
