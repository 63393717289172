import React from "react";
import '../stylesheets/BotonOpcInstMusicales.css';


function BotonOpcInstMusicales( { handleClick, texto, isClicked }){

    const handleClickWrapper = () => {
      handleClick();
    };

    return(
      <div>
        <button
          className={isClicked ? 'ambito-btn-clicked' : 'ambito-btn'}
          onClick={handleClickWrapper}
          type='button'>
          {texto}
        </button>
      </div>
    )  

}

export default BotonOpcInstMusicales;