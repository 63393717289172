import React, { useEffect, useState } from 'react';
import Cotizador from './Cotizador';
import PieDePagina from './PieDePagina';
import Encabezado from './Encabezado';
import '../stylesheets/AsesoriaPlus.css';
import fotoMarcos from '../media/marcos2.svg';
import wsp from '../media/whatsapp2.svg';
import ondaGif from '../media/ondamarcos1.svg';
import { useScreen } from './ScreenContext';

function AsesoriaPlus(){

  const screen = useScreen();

  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768); // Definir el ancho mínimo para considerar como escritorio
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Llamar a la función inicialmente para establecer el estado correcto
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  return(
    <div className='contenedor-principal'>
      <div className='contenedor-encabezado-asesoria-plus'>
        <Encabezado></Encabezado>
      </div>
      <div className='contenedor-asesoria'>
        <div className='contenedor-onda-gif'>
          <img 
            className="onda-gif-AP"
            src={ondaGif}
            height="50%"
          />
        </div>
        <div className='contenedor-gif-titulo-foto'>
      <div className='gif-container-AP'>
            <img 
            src={require('../media/Gif-Asesoria-Plus_Mesa-de-trabajo-1-01sin-halo.gif')} 
            alt='Animated Gif'
            className="animated-gif-AP"
            />
              </div>
        <div className='contenedor-texto'>
          <p className='texto-asesoria'>ASESORÍA</p>
          <p className='texto-a-tu-alcance'>A TU ALCANCE</p>
          <div className='subrayado-asesoria'></div>
        </div>
        <div className='contenedor-foto-marcos'>
        <a href="https://wa.me/5493412781694?text=Hola!!" target="_blank">
          <button className="boton-wsp-AP">
            <img 
              src={wsp} alt='Imagen WhatsApp'
              className='imagen-wsp'
            />
          </button>
        </a>
        { isDesktop &&
          <img 
            className='foto-marcos'
            src={fotoMarcos} alt='Foto Marcos'
          /> 
        }
        </div>
        </div>
      </div>
      <Cotizador></Cotizador>
      <PieDePagina></PieDePagina>
    </div>
  );
}

export default AsesoriaPlus;