import { useAuth } from '../componentes/AuthContext';
import { useState, useEffect } from 'react';

const useGetCampos = (ProTipCod) => {
  const {token, loadingToken, client} = useAuth();
  const [campos, setCampos] = useState([]);

  /*
      Obtener campos para todo riesgo electrónico e instrumentos musicales. 
      Acá se obtienen los inputs. Para todo riesgo electrónico: provincia, 
      condición frente al iva y valor asegurado. 
      Para instrumentos musicales solo valor asegurado. 
      Cada input arma un string array con los campos CamDinCod, CamDinVal, CamDinObl,
      isFile, y luego este será el valor del campo LongSDT.
      Entonces para todo riesgo electrónicos tendremos 3 {"clave": "valor"} pues son
      3 inputs. Para instrumentos musciales tendremos 1.
    */

    useEffect(() => {
      const data = {
          ce: '1',
          tp: ProTipCod,
          tipform: '1'
        };
        client.post('/rest/getCampos', data)
        .then(response => {
          const losCampos = response.data.Campos.map(item => ({
            CamDinDes: item.Campo.CamDinDes,
            IteDinDes: item.Campo.IteDinDes,
            CamDinCod: item.Campo.CamDinCod,
            IteDinCod: item.Campo.IteDinCod
          }));
          setCampos(losCampos);
        })
        .catch(error => {
          console.error('Error al obtener campos:', error);
        });
      }, []);

      return campos;
}

export default useGetCampos;