import React from 'react';
import '../stylesheets/ListaTipoSeguro.css';
import SeguroMapfre from './SeguroMapfre.js';
import accidentesLaboralesImg from '../media/productos-06.png';
import accidentesDeportivosImg from '../media/productos-07.png';

function ListaSeguroMapfre() {
  // Array de seguros con su información
  const seguros = [
    {
      iconoSeguro: accidentesLaboralesImg,
      nombreSeguro: 'AP ACCIDENTES LABORALES',
      url: 'https://alianzas.mapfre.com.ar/ap-partner/#/home?utm_cod_pas=Vm0xMFlXRXlVNlhoVGJrcE9WbFp3VUZadGVHRmlNWEJZWTBWS1VGVlVNRGs9OQ==&utm_source=Landing-Oficina-pasgosso&utm_medium=Landing-Partner',
    },
    {
      iconoSeguro: accidentesDeportivosImg, 
      nombreSeguro: 'AP ACCIDENTES DEPORTIVOS',
      url: 'https://alianzas.mapfre.com.ar/ap-partner/#/home?utm_cod_pas=Vm0xMFlXRXlVNlhoVGJrcE9WbFp3VUZadGVHRmlNWEJZWTBWS1VGVlVNRGs9OQ==&utm_source=Landing-Oficina-pasgosso&utm_medium=Landing-Partner',
    },
  ];

  return (
    <div className='lista-tipo-seguro'>
      {seguros.map((seguro, index) => (
        <div className='tipo-seguro-item' key={index}>
          <SeguroMapfre
            iconoSeguro={seguro.iconoSeguro}
            nombreSeguro={seguro.nombreSeguro}
            handleClick={() => window.open(seguro.url, '_blank')} // Abre la URL en una nueva pestaña
            url={seguro.url}
          />
        </div>
      ))}
    </div>
  );
}

export default ListaSeguroMapfre;
