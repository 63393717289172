import { useAuth } from '../componentes/AuthContext';
import { useState, useEffect } from 'react';

const useGetEnvioMail = (formularioMundoRenta) => {
  const [MensajeMR, setMensajeMR] = useState([]);
  const [errorMR, setErrorMR] = useState(null); // Nuevo estado para el error
  const { token, loadingToken, client } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loadingToken || !formularioMundoRenta) {
      return;
    }

    const data = {
      tipoRenta: formularioMundoRenta.TipoRenta,
      nombre: formularioMundoRenta.nombre,
      dni: formularioMundoRenta.dni,
      domicilioParticular: formularioMundoRenta.domicilioParticular,
      telefono: formularioMundoRenta.telefono,
      fechaNacimiento: formularioMundoRenta.fechaNacimiento,
      mail: formularioMundoRenta.mail,
      domicilioPropiedad: formularioMundoRenta.domicilioPropiedad,
      formaPago: formularioMundoRenta.formaPago,
      cbu: formularioMundoRenta.cbu,
      tipoTarjeta: formularioMundoRenta.tipoTarjeta,
      nroTarjeta: formularioMundoRenta.numeroTarjeta,
    };

    client.post('/rest/getEnvioMail', data)
      .then(response => {
        setMensajeMR(response.data.Mensaje);
        setErrorMR(response.data.Error);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error al obtener mensaje', error);
        setLoading(false);
      });

  }, [formularioMundoRenta, loadingToken, client]);

  return { MensajeMR, errorMR, loading };
};

export default useGetEnvioMail;
