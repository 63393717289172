import React from "react";
import '../stylesheets/Titulo.css';
import { useScreen } from './ScreenContext';

function Titulo( {titulo, subtitulo} ){

  const {currentScreen} = useScreen();

  console.log(currentScreen);

  return(
    <div className="contenedor-titulo">
      <div className={(currentScreen === 'Inicio' || currentScreen === 'Cotizador') ? 'titulo' : 'titulo-asesoria'}>
          {titulo}
        <div className={(currentScreen === 'Inicio' || currentScreen === 'Cotizador') ? 'subrayado-inicio'
                        : 'subrayado-asesoria-plus'}></div>
        <div className="subtitulo">
          {subtitulo}
        </div>
      </div>
    </div>
  );
}

export default Titulo;