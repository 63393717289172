import React, { useState } from 'react';
import '../stylesheets/TipoPlan.css';
import PopUp from './PopUp';
import { useScreen } from './ScreenContext';


function TipoPlan({ idPlan, handleClick, texto, EmpCod, ProTipCod, TabCod, TabCodCel, setBodyCotizacion }) {
  const { currentScreen } = useScreen();
  const [showPopUp, setShowPopUp] = useState(false); //controla visibilidad del PopUp

  const handleClickWrapper = () => {
    handleClick();
    setBodyCotizacion(EmpCod, ProTipCod, TabCod, TabCodCel);
    setShowPopUp(true); // Mostrar el PopUp al hacer clic en el botón
  };
  

  const handleClosePopUp = () => {
    setShowPopUp(false); // Ocultar el PopUp al hacer clic en x
  };

  // Función para obtener la ruta de la imagen según seguro y plan
  const getInfo = (ProTipCod, TabCodCel) => {
    switch (ProTipCod) {
      case '10':
        // Lista de planes: INTEGRAL FOOD TRUCK
        switch (TabCodCel) {
          case '1':
            return 'FoodTruck1';
          case '2':
            return 'FoodTruck2';
          case '3':
            return 'FoodTruck3';
          default:
        }
      case '12':
      // Lista de planes: ALQUILERES TEMPORARIOS
      switch (TabCodCel) {
        case '1':
          return 'AlquilerA';
        case '2':
          return 'AlquilerB';
        case '3':
          return 'AlquilerC';
        case '4':
          return 'AlquilerD';
        case '5':
          return 'AlquilerE';
        default:
      }
      case '13':
      // Lista de planes: BICICLETAS
        switch (TabCodCel) {
          case '1':
            return 'BicicletaA';
          case '2':
            return 'BicicletaB';
          case '3':
            return 'BicicletaC';
          case '4':
            return 'BicicletaD';
          case '5':
            return 'BicicletaE';
          default:
          }
      default:
    }
  };

  /*console.log('TipoPlan.js', 'EmpCod: ', EmpCod, 'ProTipCod: ', ProTipCod, 
            'TabCod: ', TabCod, 'TabCodCel: ', TabCodCel);*/

            
    // Obtener la ruta de la imagen
    const txtPopUp = getInfo(ProTipCod, TabCodCel);

  return (
    <div>
      {(currentScreen === 'Inicio' || currentScreen === 'Cotizador') && (
        <button
          className='tipo-plan-btn'
          onClick={handleClickWrapper}>
          {texto.toUpperCase()}
        </button>
      )}
      {currentScreen === 'Asesoria' && (
        <>
        <button
          className='tipo-plan-btn-asesoria-plus'
          onClick={handleClickWrapper}>
          {texto.toUpperCase()}
        </button>
        {showPopUp && (
            <PopUp handleClose={handleClosePopUp} txtPopUp={txtPopUp}/>
          )}
      </>
      )}
    </div>
  )
}

export default TipoPlan;
